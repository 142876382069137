import React from "react";
import clsx from 'clsx';
import queryString from 'query-string';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
// API Request setting
import api from 'services/api-request/api.js';

import moment from 'moment';
// core components
import { Link } from "react-router-dom";
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import IconButton from '@material-ui/core/IconButton';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import FormControl from '@material-ui/core/FormControl';
import Button from "components/CustomButtons/Button.js";
import logobig from "assets/img/logobig.png";
import InputLabel from '@material-ui/core/InputLabel';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import styles from "assets/jss/material-kit-react/views/loginPage.js";

const useStyles = makeStyles(styles);

export default function LoginPage(props) {
  const classes = useStyles();
  const queryParam = queryString.parse(props.location.search);
  const [detail, setDetail] = React.useState("");
  if (props.location.state) {
    setDetail(props.location.state.detail);
    props.location.state = null;
  }
  const [isLoading, setIsLoading] = React.useState(false);
  const [username, setUsername] = React.useState('');
  const [values, setValues] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });
  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  }

  function doLogin(e) {
    setIsLoading(true); 
    e.preventDefault();
    api.get(`/auth/login`, {
      auth: {
        username: username,
        password: values.password
      }
    })
    .then(function (response) {
      console.log(response.data.data);
      localStorage.setItem("token", response.data.data.token);
      localStorage.setItem("login_date", moment().format());
      window.location.href = queryParam.redirect ? `/${queryParam.redirect}` : '/';
    })
    .catch(function (error) {
      setIsLoading(false);
      setDetail("Terjadi kesalahan username/password, silahkan coba lagi.")
      console.log(error);
    })
    .finally(function () {
      // always executed
    });
  };
  return (
    <div style={{backgroundColor:"white"}}>
        <div className={classes.container} style={{color:"black", textAlign:"center", paddingTop:"8%"}}>
        <img src={logobig} alt="jonas-logo"/>
        <div style={{color:"black", padding:"30px", fontSize:"19px"}}>
        <b>{ detail }</b>
        </div>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
                <form className={classes.form} >
                  
                  <TextField onChange={e => setUsername(e.target.value)} id="outlined-basic" label="Phone Number" placeholder="Contoh : 62815xxxxx" variant="outlined" type="number" fullWidth/>
                  
                  <FormControl className={clsx(classes.margin, classes.textField)} style={{marginTop:"20px", width:"100%"}} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                    <OutlinedInput
                    id="outlined-adornment-password"
                    fullWidth
                    type={values.showPassword ? 'text' : 'password'}
                    value={values.password}
                    onChange={handleChange('password')}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={71}
                  />
                </FormControl>
                <div style={{marginTop:"10px", marginBottom:"10px", fontSize:"12px", textAlign:"right"}}>
                <a href="https://jonasphoto.co.id/profile/security">Forgot Password?</a>
                </div>
                <div>
                  <Button
                  type="submit"
                  onClick={doLogin}
                  style={ isLoading ? {width:"80%", height: "50px", backgroundColor:"gray"} : { width:"80%", height: "50px", backgroundColor:"green" } }
                  disabled={isLoading ? true : false}
                  >Log in</Button>
                </div>
                </form>
                <Divider style= {{ marginTop:"5%" , marginBottom:"5%" }}/>
                <div style={{color:"black", fontSize:"14px"}}>
                    Don't have an account? <b><Link to={"/register-form"} className={classes.link} style={{color:"green"}}>Register Here.</Link></b>
                </div>
            </GridItem>
          </GridContainer>
        </div>
    </div>
  );
}
